import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAccordion.figmaUrl.ios} codeUrl={checklists.componentAccordion.codeUrl.ios} checklists={checklists.componentAccordion.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p><inlineCode parentName="p">{`BaseLGNAccordion`}</inlineCode>{` is used to toggle between hiding and showing large amount of content.
Use Accordion styles to modify accordion with lots of attributes that make you easier.
It’s recommended to apply constraints in your ViewController in order to make accordion well orderly.`}</p>
    <p>{`You need to import your theme.`}</p>
    <p>{`Legion iOS UIKit offers four themes: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <p>{`then create a `}<inlineCode parentName="p">{`LGNAccordion`}</inlineCode>{` based on theme selected.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAccordion(title: "Section 1", leadingIcon: image, contentView: createExampleContentView(with: contentView1))

`}</code></pre>
    <div className="divi" />
    <h2>{`Recommended Usage`}</h2>
    <p>{`In order to make your accordion content look clean and tidy you should apply constraint before adding it to `}<inlineCode parentName="p">{`LGNAccordion`}</inlineCode>{` for example you can implement like code below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let x = UILabel()
x.text = "Hello"
x.translatesAutoresizingMaskIntoConstraints = false

let image = UIImage(systemName: "shield.fill")

let accordionView1 = BaseLGNAccordion(title: "Section 1", leadingIcon: image!, contentView: orderContentLayoutConstraint(with: contentView1))

extension ViewController {
    func orderContentLayoutConstraint(container: UIView) -> UIView {
        let contentView = UIView()
        contentView.backgroundColor = .white
        contentView.translatesAutoresizingMaskIntoConstraints = false // Make sure this is false for layout

        container.translatesAutoresizingMaskIntoConstraints = false
        contentView.addSubview(container)

        NSLayoutConstraint.activate([
            // Center the button inside the content view
            container.centerYAnchor.constraint(equalTo: contentView.centerYAnchor),
            container.centerXAnchor.constraint(equalTo: contentView.centerXAnchor),
            container.widthAnchor.constraint(equalToConstant: 100),
            container.heightAnchor.constraint(equalToConstant: 50),

            // Set the height for the contentView
            contentView.heightAnchor.constraint(equalToConstant: 100) // Example height
        ])

        return contentView
    }
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Leading Icon`}</h2>
    <p>{`Legion Have 2 Leading Icon of Accordion :`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`without Leading Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-no-leading-icon.png"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        let label = UILabel() let accordionView1 = LGNAccordion(title:
        "Section2", contentView: label )
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`with Leading Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-leading-icon.png"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        let image = UIImage(systemName: "shield.fill")! let label = UILabel()
        let accordionView1 = LGNAccordion(title: "Section 2", leadingIcon:
        image, contentView: label )
      `}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Bottom Line`}</h2>
    <p>{`You can add bottom line to accordion using `}<inlineCode parentName="p">{`HasBottomLine`}</inlineCode>{` attribute.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HasBottomLine`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-bottomline.png",
              "alt": "LGNAccordion Bottomline",
              "width": 300
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-no-bottomline.png",
              "alt": "LGNAccordion no Bottomline",
              "width": 300
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <p>{`With Bottomline`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let image = UIImage(systemName: "shield.fill")!
let label = UILabel()
label.text = "Hello"

let accordionView1 = LGNAccordion(title: "Section 1", leadingIcon: image, hasBottomLine: true, contentView: label)
`}</code></pre>
    <p>{`Without Bottomline`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let image = UIImage(systemName: "shield.fill")!
let label = UILabel()
label.text = "Hello"

let accordionView1 = LGNAccordion(title: "Section 2", leadingIcon: image, hasBottomLine: false, contentView: label)
`}</code></pre>
    <div className="divi" />
    <h2>{`Expanded`}</h2>
    <p>{`You can also set whether the accordion collapsed or not`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`isInitiallyExpanded`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-no-bottomline.png",
              "alt": "LGNAccordion Expanded",
              "width": 300
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/ios-uikit-accordion-leading-icon.png",
              "alt": "LGNAccordion Collapsed",
              "width": 300
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <p>{`Expanded/Collapsed`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let image = UIImage(systemName: "shield.fill")!
let label = UILabel()
label.text = "Hello"

let accordionView2 = LGNAccordion(title: "Section 2", leadingIcon: image, hasBottomLine: false, isInitiallyExpanded: true, contentView: label2)
`}</code></pre>
    <p>{`Minimized`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let image = UIImage(systemName: "shield.fill")!
let label = UILabel()
label.text = "Hello"

let accordionView2 = LGNAccordion(title: "Section 2", leadingIcon: image, hasBottomLine: false, isInitiallyExpanded: false, contentView: label2)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accordion’s content that will show only when it’s collapsed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hasBottomLine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Define accordion has bottomLine or not`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isInitiallyExpanded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attribute that represent if the accordion collapsed or not.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leadingIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leading icon on accordion header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accordion’s title that shows even it’s not collapsed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      